<template>
  <div class="segment">
    <div class="content" style="max-width: 960px">
     <!-- <div class="header">GAME <span class="text-highlight">RULES</span></div> -->
      <!-- POST 1 -->

      <div class="post column">
        <div class="post-banner">
          <img src="@/assets/pagebanner-rules.jpg">
        </div>
        <div class="post-content-wrapper">
          <div class="post-header">
            Download the rules as PDF here:
            <a target="_blank" href="https://berget-events.com/BergetRules2023.pdf">BergetRules2023.pdf</a>
          </div>
          <div class="post-header">
            FPS/Chroning gun rules:
            <a target="_blank" href="https://berget-events.com/Chrono2024.pdf">Chrono2024.pdf</a>
          </div>
        </div>
      </div>

      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-content">
            <h1>Behaviour</h1>
            <p>Airsoft is not a competition. There are no winners or losers. The true
              spirit of airsoft is team-spirit &amp; fellowship. Nevertheless, there are
              always a few people who cheat, some once and some regularly. Screaming or
              cursing at these players is mostly inefficient and will have a negative
              impact on the game and your experience. DO NOT argue or curse other
              players if they are not taking hits. We ask that all players avoid rules
              discussions in the game area, if there is something you feel really has to
              be discussed talk to a game master or your HQ. Make a note of the
              offending player armband number. We consider arguments about people not
              taking hits as bad as the cheating itself. Starting an argument can lead
              to a ban from the game. (And remember: it is better to take one hit too
              many than one too few.)</p>
            <p>In many games there is always some gossip about players and teams not
              taking hits or behaving in a non-honorable manner. Unless you have solid
              proof keep this information to yourself and if you should tell anyone,
              report the player ID in your discord channel or report to your fellow game
              master.</p>
            <p>Still we cannot keep our eyes on everyone and cannot and don't want to
              control the flow of the game, this is all your responsibility. Take pride
              in taking hits when you get struck by a BB. You can get back to the game
              after re-spawning. Crew and game masters will however investigate any
              reports and ban players not following our rules. We find it better that
              you forgive and forget your fellow airsofters transgressions and keep on
              having fun rather than focusing on people acting wrongfully or badly.
              Remember that you are representing your country and/or region when you
              come to Berget-games, how you act and interact with the global airsoft
              community will define how you are perceived. This is a great
              responsibility that should not be taken lightly.</p>
            <p>“A good game starts and ends with YOUR behavior.”</p>
            <h2>In game</h2>
            <h3>False flag operations</h3>
            <p>It is strictly forbidden to hide one's team affiliation or to
              pretend to be the enemy by wearing the armband color of the enemy.&nbsp;It
              is also forbidden to wear a yellow off-game vest or pretend to be a crew
              member. <strong>Yellow vest means crew</strong> so players must wear an
              orange vest or deathrag.</p>
            <h3>Props and BE equipment</h3>
            <p>All Berget props and equipment should be handled with care. Some items
              have quite a few manhours in them and the more we can keep the more we
              have for upcoming games.</p>
            <h2>Off game</h2>
            <p>Berget games take place in a natural environment on land rented by
              Berget. We are allowed to use the land for the duration of the event. It’s
              important that you bring home what you brought, meaning no littering and
              try to restore your campsite as much as possible. If you encounter
              civilians in the area (or in local towns/shops) please be friendly and
              civil. You are ambassadors for the whole airsoft community.</p>
            <h2>Crew</h2>
            <p>The crew working on Berget has had many long days before you arrive at
              the event, all crew have different assignments and can not be expected to
              know everything regarding your specific question. Try to be patient with
              the crew and we will do our best to help you. Use your Berget progressive
              web app to communicate with the crew.</p>
            <p>You will recognise the crew on our yellow vests.</p>
            <h2>Vehicles off/in game</h2>
            <p>Game master vehicles and other off-game vehicles will be flying an orange
              flag.</p>
            <h3>Main battle tanks and armored vehicles</h3>
            <p>During Berget Games you can encounter real tracked tanks and armored
              vehicles. They will Always have a GM or Trustee inside and the crew can be
              identified by their yellow crew armband or yellow crew vest/helmet. They
              are off-game, do not shoot at the crew. Tanks and armored vehicles can
              only be eliminated by the BAVS system. When tanks have to go offroad, turn
              or reverse, one of the crew will make sure that the terrain around is
              clear. When encountering a tracked vehicle, it is every player's
              responsibility to keep a safe distance from the tanks. <strong>It is
                absolutely prohibited to climb or stand or lay in the path or vicinity
                of a moving tank under any circumstances regardless if you are in- or
                off-game, on foot or in a vehicle.</strong></p>
            <h2>Bases</h2>
            <p>Main Bases are off-game 03:00-09:00 every night, and the entire last day
              (Saturday)</p>
            <p>It is forbidden to take cover inside of or fire in/out of tents.</p>
            <h3>Test firing</h3>
            <p>Sometimes you need to test your gun to make sure it functions properly.
              Before doing this, you must scream "TEST FIRE". This is to avoid the
              mistakes of "enemy" fire. All bases should have a shooting-range for test
              firing. If not, help your commander by asking where and then set one up.
              Use tools and materials that you find in your base.</p>
            <h3>Sleeping</h3>
            <p>Most participants will be sleeping in by crew provided military tents.
              There will be a 6 hour game-off during night in all base areas (03:00 to
              09:00). Keep quiet during these times and respect your sleeping teammates.
              We encourage players to sleep for 6-8 hours every 24 hours.</p>
            <p>Berget-events issue one sleeping place for every ticket holder. Worth
              noticing that we will not put furnaces in the tents so everyone should
              bring warm sleeping bags and sleeping mattress. Your gear should be stored
              under a tarp outside the tent during the night. Each tent has a capacity
              to hold 14-16 men. It is prohibited to bring larger mattresses/beds than
              200 x 60cm to tents supplied by Berget-Events.</p>
            <p>All participants can bring their own tents but the tents need to be
              military in character and hold at least 4 people. The tent must be OD or
              green camouflage. You will raise the tent in an area designated to you.
              Tents must be clearly marked with team name and what company and platoon
              the team belongs to. Base staff will show you the right location for your
              tent. The tent must be approved by Berget-events beforehand. Therefore
              send a picture and information of the tent to <a href="mailto:info@berget-events.com">info@berget-events.com</a>.
              Some teams may have different rules regarding tents, those teams will be
              informed before the game. To avoid the risk of theft, we recommend that
              your team store objects of value in a large wooden box with a lock outside
              the tent. These can be rented from Berget-events. Just email
              info@berget-events.com if you are interested. All bases are offgame
              03:00-09:00.This means that no hostile activities may be conducted in the
              bases (disturbing, sneaking into, pranking etc.)</p>
            <p>A base attack is prohibited to start later than 02:30 or sooner than
              09:30. This is to avoid late/early attacks and will ensure that players
              have a chance to sleep. If you sneak into a tent during in-game you must
              use knife kills. No firearms in/out of a tent. If sleeping inside tents
              during in-game always keep your eye protection nearby or, ideally, sleep
              with it on. From 02:00 Saturday morning, no attacks may take place towards
              any team main base.</p>
            <h2>Advertisements</h2>
            <p>You are not allowed to market companies, products or services on the game
              area, for instance on clothing, cars, tents etc. without prior consent of
              the Berget crew. To get such permission, please contact <a href="mailto:info@berget-events.com">info@berget-events.com</a>.
              We generally do not want any marketing at our games. Berget Events decide
              on these matters on a case to case basis. Berget Events reserves the right
              to remove any unauthorized signs, marketing materials and/or associated
              players from the game.</p>
            <h1>Hit</h1>
            <h2>Main rule</h2>
            <p>If you are hit, loudly and clearly shout HIT or MEDIC, take out your
              wounded flag (deathrag) and raise it. Immediately lay down and stay down
              for 10 minutes until healed or dead.</p>
            <p>After bleeding out (dead) in a fire fight, you have three options.</p>
            <ol>
              <li>
                <p>Go to the nearest friendly respawn.</p>
              </li>
              <li>
                <p>Lie down with the dead-rag on your head. Don’t move, don't talk,
                  don't disturb the fight.</p>
              </li>
              <li>
                <p>Get out of the way. Move away from the firefight and let the living
                  fight on. Make a rendezvous-point approximately 100 meters away from
                  the fight.</p>
              </li>
            </ol>
            <h3>Weapon hit</h3>
            <p>Infantry weapon hits count as normal hits if the player is holding the
              weapon at the time. Car mounted support guns are not affected by
              weapon-hits. You must take out the vehicle with B.A.V.S or hit the gunner.</p>
            <h3>Knife kills/knock out</h3>
            <p>Knife kills are used when silence is important. Sneak up behind your
              target, gently place your hand on their back and whisper "Knife kill". The
              unfortunate target must then silently lie down. They are considered dead,
              and as such return to the safe zone after laying down quiet for 10
              minutes. There is no bleed-out period. Players in the process of bleeding
              out may be put out of their misery by a knife kill.</p>
            <p>NOTE!!! You may NOT use real, rubber or LARP-style latex knives.</p>
            <p>Civilians cannot be knife killed, instead knockout is used to knock
              another player unconscious rather than killing them. The victim becomes
              unconscious for five minutes and must lie completely still on the ground.
              They may not call out. Sneak up behind your target, tap them lightly on
              the head and say "knockout". This symbolizes thumping your victim with a
              rifle butt or other blunt instrument. The target wakes up again five
              minutes later with no ill effects. This rule is also suited for
              neutralizing difficult civilians or sentries wanted for interrogation.
              Those who have been knocked out have no memory of the time they were
              unconscious. People wearing helmets cannot be knocked out.</p>
            <h3>Friendly fire</h3>
            <p>In Berget games all hits count, even if you shoot your own team. Players
              are not allowed to purposely act against their own team either directly by
              shooting or knife killing player on their own team, or indirectly by
              giving away information that would give your team a disadvantage. Example:
              Giving away the code printed on a SAM Site, or giving enemies access to
              secret chats, maps, gps systems etc. If captured and interrogated other
              rules apply.</p>
            <p>You must always take your hits regardless where the shot came from, but
              as always do not argue - Report to a GM if you feel that a rule has been
              broken and continue playing as normal. This rule does not affect civilians
              players.</p>
            <h2>The wounded flag (Deathrag)</h2>
            <p>You MUST bring your own wounded-flag to Berget and it must be orange.
              Make sure the size is at least 30 x 20 cm. You must pull it out of your
              pocket/vest when waiting for a medic, put it on your head or torso, if
              shot anyway, you may wave it above your head to let the shooter know you
              are wounded or dead.</p>
            <h2>Speaking after being hit</h2>
            <p>You may NOT use your radio or speak while you're waiting for medic or
              bleeding out. You can only shout "medic" or "hit".</p>
            <h2>Moving wounded players</h2>
            <p>Downed soldiers may also be moved by carrying or dragging them; to some
              degree, the wounded soldier may move under their own steam, but only if
              supported by a comrade. The wounded player and the helper may not use
              their weapon. Wounded players may not move from the place where they were
              shot, although if they are in the line of fire they may crawl into cover
              while shouting HIT and waving with their "wounded-flag”.</p>
            <h2>Healing</h2>
            <p>When a player is hit, after shouting MEDIC or HIT, the medic has 10
              minutes to begin treatment. If this fails, the player is eliminated and
              has to go to respawn. If the medic reaches the downed player within 10
              minutes they can treat them, by symbolically applying a field dressing.
              The dressings should be white, and may easily be made from an old sheet
              ripped into suitable pieces. It must take at least two minutes (count to
              120 sec) to apply the dressing. It will count as cheating if no dressing
              is applied when healing. All shot players must wear the wounded-flags at
              all time until respawning or healing has been completed.</p>
            <p>Knife kills are instant "death", and the player cannot be treated, no
              bleed out time.</p>
            <p>If you wish to heal an enemy player and capture them: Ask offgame if that
              is ok. If so, normal heal rules apply. If not, normal bleed-out rules
              apply. Knife kill is also an option.</p>
            <h3>The medic</h3>
            <p>A medic should have a white armband with a red cross. Civilian medics do
              not need this armband. A medic can't apply a field dressing to him or
              herself, of course another medic can heal an injured medic with a field
              dressing. A medic may carry a maximum of 20 field dressings, but may
              resupply at the BASE, SP or Respawn. Make sure you have enough dressings
              to resupply! Only medics may carry bandages.</p>
            <h3>Number of healings</h3>
            <p>Only one field dressing can be applied to a single player, on being shot
              a second time they are eliminated and must proceed to the respawn. No
              bleed out time.</p>
            <h3>Field hospital (FH)</h3>
            <p>A field hospital is a mobile tent with a medic symbol and team flag on
              it. The tent can be carried by players into position near an enemy
              location. When the tent is completely set up, it works like a mobile
              respawn where players can respawn and medics can restock their bandages.
              This is not affected by the capture points system. You can destroy enemy
              Field hospitals by packing everything down into the field hospital
              backpack and bringing it back to your HQ, but you can not use it for your
              team.</p>
            <p>The field hospital is operational once the tarp roof, barrier tape and
              white flag is up and mounted. In the field hospital you stay until
              respawn, wearing the wounded flag. Always keep your safety glasses on! If
              the enemy manages to reach the field hospital, all players inside are
              instantly killed and the enemy may capture the field hospital.</p>
            <h2>Respawn</h2>
            <p>On being eliminated, you must proceed immediately to your re-spawn where
              you will wait for up to 29 minutes before you can re-spawn and rejoin the
              game every full hour and a half. It is at all times "off-game" inside the
              re-spawn. For example: If you arrive at the respawn at 12: 59 you have to
              wait for one minute until you can rejoin the game. The guy arriving two
              minutes later has to wait until the clock strikes 13: 30 until he can
              rejoin the game. Time is counted upon entering respawn, not when you were
              eliminated. Respawn areas are located around the map, you may respawn at
              owned capture points, bases and other areas marked with a respawn sign. It
              is strictly forbidden to fire into or out of a respawn area. Weapons must
              be on safe inside the respawn. Eye protection should be used at all times.
              There should never be any kind of siege on enemy respawn areas.</p>
            <p>Certain VIPs, LARPs &amp; officers (coy leader or above) will have a
              double-length respawn time (Respawns only whole hours).&nbsp;</p>
            <p>You can respawn anywhere in your base as long as it is not under attack.</p>
            <p>(Enemy forces shooting inside base or at base)</p>
            <p>If your base is under attack you respawn in a dedicated area marked
              outside your base.</p>
            <h1>Weapons &amp; equipment</h1>
            <p>SAFETY DISTANCES</p>
            <p>NEW RULES 2024 <a href="https://berget-events.com/Chrono2024.pdf">Download
              the rules here.</a></p>
            <h2>Gun safety</h2>
            <p>At Berget games there will be a mandatory chroning at check in. Please
              get all your guns to the check-in before the game. Also, croning will be
              done randomly under the game and if we suspect cheating or as a result of
              player complaints. It is every player's responsibility to follow the
              rules, and make sure that their guns are chroned according to the
              appropriate gun classes. 8mm BB's are allowed and will be chroned with a
              separate sheet. Laser projecting sights are not allowed at Berget.</p>
            <h2>HPA-Guns</h2>
            <p>If you have a HPA-gun, you need to bring a Tournament Lock. The lock for
              pressure (at your tanks regulator) is vital to play at all, and the lock
              for the selector is needed if you want to play with velocity above 130
              m/s. We will provide some locks on site but we can't guarantee to have
              them all. <a href="https://ampedairsoft.com/tournament-locks/">https://ampedairsoft.com/tournament-locks/</a></p>
            <h2>Sniper rifles</h2>
            <p>If you want to play as a sniper your gun must look like a sniper rifle
              with scope and bipod, no mp5/uzi/m4/ak47 etc with scope are allowed as
              sniper. Semi-snipers are only allowed to be tuned to max class 4 (Se class
              list in chroning/saftey distances) The only Semi-snipers to qualify to
              class 5 &amp; 6 are Barrett m82, OSV-96, AR-50, CheyTac 50 BMG, snipex
              alligator and other 50.cal snipers alike.</p>
            <p>Dragunov may be tuned up to class 5. Bolt action snipers are allowed to
              be tuned to class 4-6.</p>
            <h2>Support weapons</h2>
            <p>Support weapons are divided into 2 classes, support weapons and light
              support weapons. Both need to be fitted with a bipod and Box/drum/cmag.
              Support weapons,(RPK, PKM, PKM versions M240, M249, M60, MK43, Mg3, AEK999
              etc) are allowed to be tuned to class 4. (see list above) Light support
              weapons (KAC LMG, Stoner mk23, L86, HK23, MG36, G36, M249 para etc) are
              allowed to be tuned to max class 2 (see list above). Special rules apply
              for the popular m249 para that can only be tuned to class 2 since it is
              lightweight and has a shorter barrel and retractable stock. No small guns
              (mp5, uzi, g36c, Ump, AKS-74U etc) are allowed as light support weapons.</p>
            <p>Turret guns mounted on vehicles do not need a butstock.</p>
            <h2>Magazines</h2>
            <p>In general, all magazines must be lo-cap (no more than 100 rounds). There
              are no restrictions on the number of magazines you may carry. Highcaps and
              midcaps with capacity over 100 are strictly forbidden. If you have a
              weapon where lo-caps are unavailable, mark your magazines with blue tape
              saying "RAL'' (restricted ammo limit) and load no more than the limits
              above. However, it is still forbidden to use wheel or electric reloading
              high capacity magazines enabling the player playing with only one magazine
              with easy reloading options (Support weapons excluded).</p>
            <p>Sniper weapons are limited to max 30 rounds. Light support weapons (KAC
              LMG, Stoner mk23, L86,HK23, MG36, G36 etc) may use C-mags limited to 500
              rounds. Support weapons (RPK, PKM, PKM versions, M240, M249, M60, MK43,
              Mg3) 3000-5000 round/mag. To summarize: Standard: Max 100 rounds/mag.
              Snipers: Max 30 rounds/mag. Light Support Weapon (KAC LMG, Stoner mk23,
              L86,HK23, MG36, G36 etc) : Max 500 rounds/mag. Drum or box mag. Support
              Weapon (RPK, PKM, M240, M249, M60, MK43, Mg3): 3000-5000 rounds/mag. Drum
              or box mag. For game balance purposes, the RPK light support weapon will
              count as a full support weapon for purposes of trim, magazines etc. This
              is an exception to the normal rules, and applies only if the weapon is an
              actual RPK (original or AK conversion; key features include bipod, long
              barrel, RPK stock) - an AK47 with a drum mag and bipod will not count. It
              should be noted that magazines with more than 100 rounds must still be
              drum or box style - no "ordinary-style" hi caps. KAC LMG, Stoner mk23 do
              not need to be fitted with bipods.</p>
            <p>The M249 para may use drum or box mag with 3000-5000 rounds/mag.</p>
            <h3>Reloading ammunition</h3>
            <p>In addition to any ammunition carried in magazines, you may carry up to
              3.300 rounds (10.000 for support gunners) to reload in the field. You can
              top up your ammunition allowance in your home base, Safe-zone or while
              re-spawning. There is no limit on ammo you may store at your base. It
              should be noted that the 3300 (10000) rounds are per player, not per
              weapon.</p>
            <h2>Berget anti-vehicle system (BAVS)</h2>
            <p>Berget has developed the anti-vehicle system used in our games. It works
              using IR light and all in-game vehicles are equipped with IR receivers,
              lights and alarm. When hit using an IR equipped weapon, the vehicle may
              take hits and, depending on hit points, be disabled. When disabled there
              will be a red light blinking and the alarm will sound.</p>
            <h3>AT weapons</h3>
            <p>The AT-weapons is built upon a IR-encrypted system similar to the
              military SIMFIRE system. BAVS can shoot up to 60 meters in daylight and
              even further at night. The BAVS AT4 anti-tank weapon has unlimited shots.
              When the AT4 is started it has 0 shots. It will begin to load, one shot
              every 5 minutes until maximum 5 shots. When at least one shot is loaded,
              you can fire. After firing one or more shots, the AT4 will begin loading
              again with one shot every 5 minutes.</p>
            <p>When firing, the AT4 will make a sound and flash a light. The AT4 is
              equipped with a target finding sight and you need to keep the target on
              site for a few random seconds before the AT4 will fire. You need to press
              and hold the trigger while aiming at the vehicle until the weapon makes a
              firing sound. Some AT4's will be handed out by each team's commander
              during special missions and needs to be signed for.</p>
            <p>When shooting an armored vehicle, if the range is more than 20 meters you
              can aim in the middle of the vehicle and score a hit. At close range, aim
              at the sensor mounted on the roof. Armored vehicles have 5 hit-points, non
              armored vehicles have 1-3 hit-points. There is a randomizing factor
              regarding hits and damage. If you score one wounding hit you can hear a
              short alarm sound. A disabled vehicle will have a longer 10 second alarm.
              The BAVS 40mm grenade works in the same way as the AT4 but it deals less
              damage. For example if you shoot at a bunker with an AT4 and it is
              destroyed after three hits you will need five or more hits with a 40mm
              grenade to successfully penetrate the bunker. In favor of the 40mm grenade
              it is very light and it reloads faster than the AT4. On the downside the
              40mm cannot penetrate the armor of a tank.</p>
            <p>NOTE! Try to protect the AT-weapons from rain and water. If soaked they
              can stop working. Also keep the lens free from dust &amp; dirt until
              firing</p>
            <h4>Tube launched optically tracked wire guided weapon - TOW</h4>
            <p>TOW and movable anti tank guns is a unit that can be equipped to a
              vehicle or used as a stationary anti tank weapon. The BAVS units used in
              these weapons are provided by Berget along with instructions on its usage.
              You may not steal enemy TOWs in or offgame.</p>
            <h2>Mines</h2>
            <p>There are different types of mines and minefields you can run into during
              Berget games.</p>
            <h3>Anti-Vehicle Mine</h3>
            <p>The mines are disc-shaped, made of wood/plywood/metal. They are 30cm wide
              and minimum 5 cm in height. A small piece of reflective tape must be on
              top of the mine. One wide circle and one small circle on top of it. They
              may NOT have any sharp edges and must be easy as safe to drive over by any
              kind of vehicle. They must be OD GREEN in color. (Plastic mines are
              forbidden)</p>

            <img src="@/assets/mine.jpg" />

            <h3>Anti-Personnel Mine</h3>
            <p>Mines may be found in the game area, and you will notice if you set one
              off. Anyone within 3 meters of a detonating mine is considered hit, and
              should react accordingly. Only engineers may disarm mines. There are
              beeping mines and BB-shooting mines. All inflict a normal "hit" injury.
              All retail gas/spring/bungee/electrical mines and grenades are ok.
              Home-made stuff must be tested by the crew at check in. No burning,
              smoking or exploding mines are allowed.</p>
            <h3>Different minefields</h3>
            <h4>Static minefields</h4>
            <p>Static Minefields are minefields that have been pre-defined by Berget
              Events, and will be displayed on maps prior to game start. These
              minefields will be marked on site with tape (usually yellow/black) and
              signs warning that it is a Minefield. You are not allowed to cross static
              minefields either on foot or in vehicles, as they consist of both
              anti-vehicle and anti-personnel mines. Static minefields cannot be
              defused/cleared, but special missions may be created to clear a path
              through the minefield. Walking into a static minefield is considered
              suicide, and you are instantly killed. Crossing a static minefield is
              considered cheating.</p>
            <h4>Dynamic minefields</h4>
            <p>Dynamic Minefields are minefields that are placed by players. They are
              placed by Engineers and can be defused by engineers. The mines are made of
              wood, are in OD Green color and may be booby trapped. They are visually
              placed on top of the ground on roads against vehicles. Mines may not be
              buried, covered or hidden. The main purpose of a dynamic minefield is not
              to disable vehicles, but rather to create roadblocks to halt enemy
              mechanized movements.</p>
            <h3>Placing mines / creating dynamic minefields</h3>
            <p>Minefields may be created by either side engineers at any time and any
              location without needing pre-approval. Placing mines takes 60 seconds for
              each mine, only engineers can place them by putting them open and visible
              on the ground. The engineer must hold their hand on the mine for 60
              seconds before moving on to the next. Engineers may cooperate and place
              one mine each. Mines may not be hidden, buried or covered in any way, and
              must be visible to drivers. Place the reflective tape facing oncoming
              vehicles. Make sure no mines are already placed nearby, &lt;100m.</p>
            <p>A road is considered mined when it is covered by 3 mines covering the
              WIDTH of the road.</p>
            <p>Example: You may place 3 mines in maximum 2 rows along the length of the
              road as long as it maintains 3 in width to stretch across the entire road.
              You CANNOT mine a road with only 1 or 2 mines. You may only place a
              maximum 2 rows of mines for every 100 meters of road.</p>
            <p>You cannot place more than 3 mines in one row.</p>
            <p>Crossroads can not be mined. In a 3 or 4 way crossroad, you can choose to
              mine one of the roads.</p>
            <p>Example 1: You decide to mine a road with 3&nbsp;mines. You can not place
              any more minefields within 100m. Make sure no mines are already placed
              nearby, &lt;100m. Example 2: You decide to mine a road with
              6&nbsp;mines&nbsp;in two lines close together. You can not place any more
              minefields within 100m. Example 3: You decide to mine a road with
              3&nbsp;mines, and then you walk 20m to Place Another 3&nbsp;mines.&nbsp;*
              NOT ALLOWED*</p>
            <p>A mined road is mined for all teams, hostile as well as friendly. Special
              rules may apply but those will be announced before each game.</p>
            <p>If an engineer fails to disarm the minefield (1 row) and is killed before
              completion. The minefield is not disarmed and the next engineer will need
              to disarm all mines in that row.</p>
            <h3>Clearing dynamic minefields</h3>
            <p>Engineers may defuse dynamic minefields at any time. This is done by
              engineers keeping one hand on the mine for 2 minutes. Only one mine per
              engineer can be cleared at one time (Cannot have one hand each on two
              mines), and only one engineer can work on one mine at a time. (2 engineers
              on one mine will still require 2 minutes). A minefield is not cleared and
              cannot be crossed until ALL mines have been cleared. A mined road (3 mines
              over the width) is NOT considered cleared by only removing one mine.</p>
            <h4>Booby trapped mine</h4>
            <p>Some mines can/may be trapped. Those mines have an alarm under the mine
              designed to start beeping or making an alarm sound when you lift them up.
              When an alarm on a mine goes off for any reason, everyone within 3m of the
              mine is considered HIT and follows normal HIT Rules. A booby trapped mine
              that goes off does not damage nearby vehicles. An engineer may carry a
              maximum of 3 mines, and only one of these 3 may be booby trapped, but
              mines may have "fake booby traps" on them, for example visible wires.
              Mines can only be carried and used by engineers!&nbsp;</p>
            <h3>Anti - mines</h3>
            <h4>Mine worm</h4>
            <p>The mine worm is a long wire equipped with small explosives. The weapon
              can be used to quickly clear the road from mines. Engineers place the mine
              worm and light a fuse, detonating the chain of explosives and the mines
              clearing the road.&nbsp; (The engineer can drag the mines off the road
              after a small pyro explosion and vehicles can pass directly after the
              explosion.) The mineworm needs to be rearmed after 1 use in the base
              before next use. The mine worms are provided by BE and will be delivered
              to "some" engineers by BE (It is not allowed to bring on your own).</p>
            <h4>Minesweeper/mine flail</h4>
            <p>A mine flail is a vehicle-mounted device with steel chains that makes a
              safe path through a mine-field by deliberately detonating landmines in
              front of the vehicle that carries it. The Mine flail can instantly destroy
              3 x 2 mines (by driving over them) before needing to be repaired/replaced
              by the tank crew (takes 1 hour cooldown).Only Heavy armored, tracked
              vehicles can use mine flails during Berget-games and they must be accepted
              by Berget-crew pre-game.</p>
            <h2>Hand grenades</h2>
            <p>We allow both sonic Berget grenades and gas driven BB shooting grenades
              like Tornados. For all BB shooting grenades normal hit rules apply. Sonic
              grenades (BAPS) If you are in a room when a sonic BAPS grenade detonates -
              you are hit. (No matter if you are behind furniture or other cover, you
              are hit.) You must put up a death-rag and yell “HIT”. If a player enters a
              room during the 10 second "detonation"-sound he is also hit. Normal
              HIT-rules apply. You may NOT reset a thrown grenade and throw it back!
              Only the owner of the grenade can reset it. You may NOT retrieve your
              thrown grenade during an ongoing firefight in a building. The BAPS grenade
              effect does not work outdoors, the rules only apply for indoor use in
              bunkers, cars, tents, rooms or buildings like the FAD or forts. It is
              important to know that the grenade does not eliminate a whole house, only
              people in the room where the grenade is thrown. Inside a fort, FAD or
              larger the damage is 3x3 meters If you are OUTDOORS and a BAPS grenade
              detonated near you (beep beep beep) you are NOT hit. (Use other approved
              grenades in these situations) You may only throw the grenade through doors
              or windows. The sonic grenade is the only grenade you are allowed to use
              against cars with open turrets or windows. If using a grenade against a
              vehicle, you can only “drop” the grenade into an open window or
              turret/hatch. Never throw the grenade from a distance. If a BAPS grenade
              detonate inside a vehicle, all players inside are hit. The vehicle is NOT
              destroyed. You may never approach a MOVING vehicle to drop a grenade.</p>
            <h2>Missiles, mortars &amp; airstrikes</h2>
            <p>These area-of-effect weapons are only used coordinated with a game-master
              using pyro to simulate. If you hear the pyro you are in the area of effect
              - take cover. The proximity of these weapons may vary. Game masters will
              let you know if you are hit. To avoid being hit you must take cover, the
              better cover you choose the lesser risk you have of being hit. But you CAN
              be hit anyway. Always obey the game master's ruling if you are hit or not.
              Only Commanders and forward observers will be able to use area of effect
              weapons.</p>
            <h2>Explosives/sticky bombs</h2>
            <p>Explosives may be used to destroy objects like buildings, radio masts and
              doors. Only engineers may use explosives. Use of explosives requires a 10
              meter safety perimeter, and enforcing this is the engineer's
              responsibility. Explosives may only be used against objects, and they may
              not be thrown. They must be placed carefully. Rigging the charge must take
              at least two minutes. The charge we use is completely legal and detonates
              like a small firework. In some cases it is just a dummy. Players may not
              construct their own explosives; all relevant players (engineers) will be
              briefed and issued explosives on the day of use.</p>
            <h2>Weapons of mass destruction</h2>
            <p>Players are not allowed to create, invent or use any kind of prop or made
              up story involving weapons of mass destruction such as nuclear bombs,
              biological weapons or any other type of weapon that directly or indirectly
              affect large groups of people. While these kinds of weapons might exist in
              some of the stories of some Berget games, they will then be clearly
              announced and provided by Berget Events. A special rulesset will be
              written for these weapons.</p>
            <h2>Pyrotechnics and smoke</h2>
            <p>No player brought pyrotechnic devices or smoke may be used within the
              game area. No player may bring any form of pyrotechnics, burning, smoking,
              hand grenade, flashbang device, whether commercial or home-made.</p>
            <p>Smoke or pyrotechnics can be provided by the crew to selected players.</p>
            <p>Car mounted smoke is allowed and must be registered and approved by the
              Berget crew at BAVS-mounting.</p>
            <h2>Helmets, body armor and ballistic shields</h2>
            <p>Helmets and body armor have no in-game effect regarding hits. Helmets
              protect players from being knocked out. Ballistic shields may not be
              brought, though a few may be available and issued when appropriate by your
              commander in special missions.</p>
            <h1>Vehicles in game</h1>
            <p>There will be both military and civilian vehicles moving around in-game.
              These may be friendly or hostile and may be fired upon. Their crew or
              drivers may be eliminated by BBs alone if the vehicle has their side
              windows down or are open vehicles like ATV's or Jeeps. Personnel sitting
              in the back of a pick-up truck apply to normal hit rules. To avoid people
              from shooting out from small cracks in the side windows and then close the
              window to be protected when fired upon. Vehicles that want to shoot out of
              their side windows must keep their side windows down to the lowest point
              and secure the glass with black or OD duct tape so they may not be
              raised.&nbsp;You may never attack a vehicle by opening a door to an
              in-game vehicle to shoot or throw a grenade into it.&nbsp; All
              participants bringing an in-game vehicle have to mark their vehicle with
              the TEAM-color on the front windshield and with a small flag on top of the
              car (40x20cm).&nbsp; All ordinary civilian vehicles are considered light
              vehicles. You will need to hit the vehicle with a BAVS weapon to eliminate
              it.</p>
            <p>Some civ/light vehicles may not have a BAVS system mounted. These MUST
              have open windows at all times to enable players to destroy the
              driver/vehicle with normal airsoft guns. ATVs, motorcycles and vehicles
              where the driver is unprotected does not need a BAVS mounted.</p>
            <p>Most vehicles in-game will be mounted with the BAVS 2.0 - 3.0 IR system
              (Berget anti vehicle system) that can be rented from Berget. If you want
              to bring a in-game vehicle contact info@berget-events.com</p>
            <p>Berget will provide an “vehicle kit” at check in, containing items needed
              during the game. Towing lines and markers for example.</p>
            <h2>Main battle tanks &amp; armored vehicles</h2>
            <p>Armored vehicles have reinforced active armor, Anti-Tank weapons only
              have a 33% chance to penetrate the armor and damage the armored vehicle.
              If the armored vehicle is hit but no damage is dealt, the BAVS will
              indicate a different light and no-penetration-hit audio sequence. 40mm
              grenades do little to no damage to tanks. You must use AT4 or TOW
              launchers. Armored vehicles can be equipped with a visible minesweeper in
              the front of the vehicle, which can instantly clear 3 lines of mines
              before the minesweeper equipment is destroyed. The minesweeper can be
              re-equipped at home base only. The vehicle repair station can repair the
              minesweeper equipment. Repair time is 10 minutes.</p>
            <p>Armored vehicles also have access to high explosive (HE) fragmentation
              grenades. With these grenades, the armored vehicles can shoot against
              visible infantry up to 100m away. These grenades work like artillery
              strikes, and have an area of effect where people are injured or killed.
              The effect of the shots will be decided by the crew GM who determines who
              is hit or killed. Taking cover reduces the risk of taking damage while
              running or standing up increases the chance of being hit. The armored
              vehicle can continue to shoot as long as they can pinpoint infantry
              positions through the tank sight. The GMs decision on who is hit or not is
              final without any discussion.</p>
            <p><strong>Note!</strong></p>
            <p>Players who operate heavy vehicles class 5 (Tanks and 6 wheeled trucks)
              have one or more dedicated operators who will wear an orange vest with the
              text "Vehicle security". They are allowed to move around the vehicle to
              ensure that no one is injured when reversing or turning.</p>
            <p>"Vehicle security" can therefore move outside the vehicle offgame and
              then enter and take off their orange vest to continue the game. You must
              not under any circumstances shoot at vehicle crew when orange.</p>
            <p>These instructions and vest will be provided for you at vehicle check in.</p>
            <h2>Drones &amp; UAVS</h2>
            <p>It is prohibited for players to use any kind of drones or UAVS during the
              game, unless you belong to a UAV unit and have received special training.
              Some games might allow selected players/groups to bring drones and UAVS
              for attack and/or reconnaissance. Do not fire BB:s on drones &amp; UAVs.</p>
            <h3>Berget anti-air system (BAAS)</h3>
            <p>All ingame flying objects will be mounted with a BAVS receiver. Any kind
              of BAVS weapon can be used to disable all ingame flying objects.</p>
            <h2>Vehicle classes and hitpoints</h2>
            <p>Vehicles are divided into different classes with different amounts of hit-points. You can recognize the armor level on the stick of the BAVS receiver. One line of yellow = one hit, three lines of yellow three hits etc.</p>
            <p>Class 1 ............... Light vehicle .......................................... 1 hit-point</p>
            <p>Class 3 ............... Armoured fighting vehicle ..................... 3 hit-points</p>
            <p>Class 5 ............... Heavy armoured vehicle / Tanks ......... 5 hit-points</p>
            <p><strong>Class 1 - Civ/Light vehicles/Technical:</strong> Can have mounted weapons. If a weapon is mounted, there can only be a front protective plate, MAX 90&deg; protection. Protection plate is not mandatory. No other requirements.</p>
            <p><strong>Class 3 - armored fighting vehicle</strong>: Must have mounted weapon with at least front and side protection. Vehicle and turret must have "protective plates" on sides and front. Vehicle must overall be visually improved to look "armored".</p>
            <p><strong>Class 5 - Heavy armored vehicle:</strong> Must be a large vehicle with at least 6 wheels or track. Must have protective plates on all sides and windows. Must have a mounted gun. Vehicle must overall be visually improved to look "armored". (This class is hard to obtain! Send a picture before you start building to get pre-approved.) No remote controlled turret is allowed in any vehicle regardless of armor class. NOTE! Mounted guns are not affected by weapon-hits. You must take out the vehicle with BAVS or hit the gunner.</p>
            <h2>Speed/Conduct</h2>
            <p>Player vehicles (ingame or offgame) may not travel at more than 30 kph
              for safety reasons. Only authorized drivers may drive ingame vehicles
              during the game, and only those vehicles that have been assigned to them.
              It is strictly prohibited to just get in a random vehicle and drive off.</p>
            <p>Berget game master/staff vehicles with yellow markings are not affected
              by this restriction.</p>
            <p>Warning lights are to be used only on Offgame/Destroyed vehicles.</p>
            <h2>Vehicle elimination</h2>
            <p>Vehicles can have three different states.</p>
            <ol>
              <li>
                <p>Ingame functional.</p>
              </li>
              <li>
                <p>Disabled. Repairable by engineers.</p>
              </li>
              <li>
                <p>Offgame/Destroyed. Must respawn in base.</p>
              </li>
            </ol>
            <p>When a vehicle is disabled by a BAVS-weapon. ONLY the driver must take a
              hit (can be healed by medic), the rest of the players inside of the
              vehicle can choose to be eliminated or continue fighting. If you choose to
              be eliminated you may stay in the vehicle wearing a deathrag. If you wish
              to continue fighting, leave the disabled vehicle within 20 seconds. You
              cannot shoot from a vehicle that is disabled, a disabled vehicle must move
              to the side and turn off their engine. However, the vehicle can be moved
              immediately by applying the Towing-rule.</p>
            <p>To destroy a vehicle, flip the “destroyed” velcro patch on the windshield.
              Vehicle must now respawn in base. It cannot be repaired by engineers. Warning
              lights are to be used only on Offgame/Destroyed vehicles.</p>
            <p>If there is no Velcro sign, the engineer can use a vehicle destroyed sign to mark his vehicle destroyed.
              These are available upon check-in.</p>
            <p>For safety reasons, vehicles must at ALL TIMES keep the road drivable. If
              your vehicle is eliminated in a spot where it blocks off the road, you
              must directly move it so that other vehicles can pass. Safety first! We
              know that this makes it impossible to stop an ingame convoy by taking out
              the first vehicle with BAVS. In order to do so you must use mines.</p>
            <p>All destroyed/off-game vehicles must show this clearly by having orange
              flags on both sides of the vehicle (left/right).</p>
            <h3>Vehicles hit by mines</h3>
            <p>If a vehicle drives over a mined road, normal hit rules apply. Just as if
              it was disabled by BAVS. When a vehicle is disabled by a mine, ONLY the
              driver must take a hit (can be healed by medic).</p>
            <p>An disabled vehicle must be moved to the side of the road.</p>
            <p>It is absolutely prohibited to drive around a minefield. Vehicles must
              always stay on the road, and may never use the ditch to try to pass around
              obstacles for safety reasons.</p>
            <h3>Repair &amp; respawn</h3>
            <p>The disabled vehicle can be repaired by an engineer with 30 min repair
              time, the repairs must be made by holding the front of the car. If two
              engineers are available the repair time is 15 minutes. A destroyed vehicle
              can not be repaired.</p>
            <p>The vehicle may return to respawn flying an orange flag or marking, to
              indicate that they are off-game. The vehicle should raise an orange flag
              during repair time. If unable to repair, the vehicle has a 10 minutes
              burnout time before the vehicle can choose to drive back to base off game
              and respawn in base. Respawn time in base is 2 hours.&nbsp; The driver
              respawns with the vehicle.&nbsp;</p>
            <h4>Vehicle repair station (VRS)</h4>
            <p>Engineers can build a repair station to quickly repair disabled vehicles.
              The repair station consists of a props pallet and a sign. The station must
              be placed on a road, clearly visible. Respawn time is 10 minutes. Only one
              vehicle can be respawn at a time. Vehicles must be towed to the repair
              station and park within 2 meters of the sign/pallet.&nbsp;With the station
              operational vehicles can repair, refuel and restock ammo for any friendly
              vehicles.&nbsp;An engineer does NOT need to be present during repair in
              the VRS. Only the players in the disabled vehicle will also respawn in 10
              minutes at the VRS. Repair time for class 5 vehicles is 30 minutes.</p>
            <p>Only one Repair station can be placed at a time, and it can be destroyed
              by the enemy approaching the VRS and mark it as destroyed by turning a
              sign on the pallet. The VRS must be returned to base and be respawned for
              6 hours. Place it and camouflage it well (VRS signs and props must be
              visible from the road).&nbsp;</p>
            <p>Can only be built by certain units.&nbsp;</p>
            <h4>Repair and recovery vehicle (RNRV)</h4>
            <p>Each side may designate a vehicle that can act as a mobile repair
              station. This means that the vehicle can be set up next to a friendly
              capture point (within 50 meters). This vehicle can then function just like
              a repair station. As long as it is stationary and the engine is switched
              off. The vehicle must be ingame and at least one person must be outside
              the vehicle during the entire repair time. RNRV vehicles must have a sign
              inside their windshield stating “RNRV” and each team may only have 1 RNRV
              in their team.</p>
            <p>Ordinary repair station rules apply.</p>
            <h4>Towing</h4>
            <p>There is an option to "tow" a vehicle to a safe location for repairs or
              to move an enemy vehicle out of the road. Which means that a symbolic rope
              should be tied between the vehicles and the "damaged" vehicle can be moved
              out of harm's way. The symbolic rope must be disconnected before the
              vehicle starts to move, to avoid injury. There is no maximum travel
              distance for towing a vehicle. The towing rope should only be symbolic and
              for safety reasons the player initiating the towing should walk between
              the two vehicles with a rope and a marker/flag "TOW-LINE" in the middle of
              the "rope/line". The minimum time is 2 minutes to get the vehicle ready to
              be towed. The "towed" vehicle should of course be driven by its dedicated
              driver while moving, the tow wire must be removed before driving. The
              vehicles must move at a slow walking pace while towing (5-7 km/h) and 5-10
              m between the vehicles.</p>
            <p>The vehicles involved in the towing procedure must have a sign in their
              windshields, it will be provided by BE at vehicle check-in. The driver of
              the damaged vehicle is considered off-game while moving to the repair
              location. You can stop the towing by destroying the in-game vehicle.</p>
            <h1>Battle elements</h1>
            <h2>CQB</h2>
            <p>All indoor play (CQB) is limited to weapons with a muzzle velocity of no
              more than 109 m/s (357 fps) class 1. Long guns must be fired from the
              shoulder as far as possible. Random fire is not permitted, and ricochets
              do not count as hits. It is forbidden to take cover inside of or fire your
              weapon in/out of tents. Get out of the tent if you want to engage in the
              fight.</p>
            <h2>Locked/barricaded doors</h2>
            <p>Some buildings will be locked and can only be opened by engineers. It
              takes 2 minutes per door. This is indicated with a BE instructions sign on
              the door.</p>
            <h2>Parachute drops</h2>
            <p>The HQ may gain access to a limited number of parachute drops. When the
              commander orders a parachute drop, the designated players will be driven
              by crew in an off-game vehicle to the appointed drop zone. All parachuted
              players may be shot upon immediately after exiting the vehicle. Therefore
              great care should be taken into account while planning the drop zone. The
              opposing faction will also get a 3 min notice if the drop is planned in
              close vicinity of their base.</p>
            <h2>Helicopters &amp; airplanes</h2>
            <p>If there is a helicopter in-game, it is strictly prohibited to shoot
              against the helicopter for safety reasons. You can disable it by using
              BAVS issued by Berget-Events. Players may not touch or get into a landed
              helicopter. It is strictly forbidden to run under the helicopters while it
              is landing or in flight in close proximity to the ground.</p>
            <h2>Cameras &amp; computer vision</h2>
            <p>Players may not place any cameras or recording devices anywhere in the
              game area. This is prohibited by Berget (and Swedish law). Handheld
              devices are allowed to be used but not remote or sensor triggered devices.</p>
            <h1>Teams &amp; uniforms</h1>
            <p>To differentiate between the various groups in the game, players will
              have various armbands &amp; uniform elements. Each event will have a
              different number of teams and their armband and uniform may change between
              games. You can read about the requirements under the section "teams” when
              the new story is presented on our webpage.</p>
            <h2>Uniform guidelines</h2>
            <p>You must look like a soldier. Uniform, boots and all your combat gear
              must look the part. No brightly colored clothes or sneakers, sweatpants or
              other civilian clothes. This does not apply to civilians or other special
              teams. Check your team's description on the type of uniforms you are
              allowed to use.</p>
            <h2>Armband</h2>
            <p>You must always have a clearly visible armband. At all times, other
              players must be able to see and identify your team color. To hide, cover
              or obscure your armband is forbidden.</p>
            <p>Only snipers wearing full ghillie suits may use their armband on their
              legs.</p>
            <p>You will have to buy or create your own armband. When constructing your
              own armband, it must be at least 10 cm (3.94 in) wide and have your
              individual team-number and unit-name visible in a contrasting color.
              Players without correct armbands won't be allowed into the game-area.
              Correct armbands with your individual team number and unit-name can be
              bought when booking your ticket and received at game-area check-in. Do not
              create your armband until you have received your individual "Armband-id".
              The armband-id is not the same as your ticket-id! You will receive it when
              you book your ticket, if buying a second hand ticket please ask
              Berget-events or the seller for the correct armband-id. All players need
              to follow their team requirements for uniform and armbands. You have to
              make a secondary armband if you are an "in game" medic or engineer. This
              should be worn on your right arm and the "team-armband" on your left. This
              armband should be in the same size as the "team-armband".

            </p>
            <p>This is the engineer symbol:</p>
            <p>
              <img src="@/assets/engi-small.jpg">
            </p>

            <p>This is a medic symbol:</p>
            <img src="@/assets/medic.jpg">

            <p>Any questions about this should be sent to info@berget-events.com</p>

            <h2>Command structure</h2>
            <p>During Berget games most players will be playing in teams and groups
              using a command structure. This means that you and your friends will play
              a part in bigger groups called a "Platoon" and "Company". The smallest
              unit is called a squad which consists of 6-10 players. You will choose a
              squad-leader and a secondary squad leader. The squad leader will have
              leaders above him passing down information and missions during the game.
              It is important that you listen and follow the orders and directions from
              your squad leader or platoon leader. Most missions during the game will be
              platoon sized. As soon as you arrive at your "Base-area" make sure your
              squad leader reports your presence to your "platoon leader". He can direct
              you where to sleep etc. If you have problems with taking part in some duty
              or mission, remember to inform your squad leader about this. If you feel
              ill or tired, there is no shame in taking a rest and skipping a mission.</p>
            <h3>Going rouge or mutiny</h3>
            <p>Going rouge means ignoring missions and orders and just going around
              looking for airsoft fights on the map instead of helping your team,
              following orders and respecting your commander. In each game there are
              less military structured teams to choose from, often yellow or green/white
              fractions.</p>
            <p>If you have chosen to play in a unit that has a military structure
              (normally like the big teams red and blue), you must follow your platoon
              or company commander's instructions and orders. If you feel that you only
              get boring assignments without action or basic answers, you should
              immediately talk to your commander offgame and try to solve the issue.
              Bring your own solutions to the table. If this does not work, talk to a GM
              and ask to be reassigned to another team.</p>
            <p>Going rouge or ignoring the platoon leader's order to run out on your own
              missions can lead to you being forced to change teams to civilian/bandit
              units and get a warning from GM.</p>
            <h3>Officer markings</h3>
            <p>To help friendly players to identify their officers and to be able to add
              interesting missions to the enemy. We are using a mandatory officer
              marking system. We will provide this to all officers Plt, Coy and Bat.
              However Squad Leaders are allowed to purchase their own marking by
              preorder or on site. Officer markings for squad leaders is not a
              requirement.</p>
            <p>This is the officer markings.</p>

            <img src="@/assets/cmdarmband.jpg" />

            <p>The marking must be put on the left arm or on the chest. It will have a
              velcro-side. The marking must be your team color in w 10 x h 4 cm. You
              must wear your officer marking at all times. It is forbidden to steal,
              borrow or use an officer patch if you are not a commander with that rank.
              All officer patches shall be returned to Safe-zone HQ after the game.</p>
            <h2>Bases</h2>
            <h3>Fuel and ammo depot (FAD)</h3>
            <p>You will be able to make a great deal of damage to your enemy's support
              and tactics by sneaking in and sabotaging their Fuel and Ammo depot that
              will be located in close proximity to their base. The FAD is placed close
              to the base as a target for base attacks, to add a valuable target for
              attacking the enemy base.</p>
            <p>If you find this point it will be marked by ammo boxes and fuel
              containers. Successfully destroying a FAD does not only bring great
              advantage to your team but also great honor to the group that succeeds in
              this task!</p>
            <p>Destroy the FAD by capturing it, push the button (same as on CPs) and
              hold for 30 mins, then push the button again.&nbsp; The Fuel and Ammo
              depot can only be destroyed during 09:00 and 03:00, it will be off game at
              the same time as the base. From 03:00 to 09:00 you cannot go closer than
              100m to an enemy FAD.</p>
            <h1>LARP elements</h1>
            <h2>Taking captives</h2>
            <h2>Confiscations</h2>
            <p>If a person is arrested or taken prisoner, their weapon will be
              confiscated. This is symbolized by making the weapon safe and tying a
              section of barrier red / orange tape round the barrel or body. The weapon
              and its accessories are retained by the owner, who carries it slung if
              possible. If the prisoner approves, others may carry the weapon: it should
              always be within the prisoner’s sight. If the prisoner escapes or is
              released, they may not use their weapon until five minutes later. It is
              not allowed to take an enemy weapon to use. A prisoner cannot use knife
              kills but knockouts may still be used.</p>
            <h3>Searching a captive</h3>
            <p>If both players are willing to LARP, go ahead and search the captive. If
              any player gets uncomfortable, say “Offgame”. Both parts must immediately
              stop and back off.</p>
            <p>If you do not want to LARP, clearly say to the captive: “You are hereby
              searched.“ The captive MUST comply and can not use any (hidden) weapon
              against his captors. Any hidden in-game materials like maps and codes etc
              MUST be handed over to the captors.</p>
            <p>All electronic devices are considered encrypted and cannot be accessed,
              the prisoner can not be made to open the phone/GPS or any other electronic
              device.</p>
            <h4>Prisoners</h4>
            <p>Prisoners may be taken in-game, whether for arrests, prisoner exchanges,
              ransoms or information. However, there are strict rules to follow.</p>
            <p>First the “prisoner” must agree to be taken “prisoner”.</p>
            <p>The “prisoner” can at any given moment decide to stop the ongoing larp
              situation by going off game.</p>
            <p>Restraints like handcuffs, flexi-cuffs etc. that are securable/lockable
              are not allowed. The only restraints allowed are velcro-straps or
              breakable tape.</p>
            <p>Hands must always be secured in front of the body; never behind. The
              person being captured must be able to release themselves easily off-game.
              Ask the prisoner if the restraints are too tight, If a prisoner escapes,
              they should be able to remove their restraints easily.</p>
            <p>Prisoners being held in tents or buildings may not be restrained, for
              fire safety reasons.</p>
            <h2>Calling game off</h2>
            <p>If you at any time feel uncomfortable, or if you want to break for any
              reason, simply say off-game. You then cease to exist in-game, and everyone
              around you continues as if you were not there. This is particularly
              intended for people who do not wish to be searched or restrained. If you
              call “game off”, you must hand over any and all information you may have.
              It may not be used as a loophole to get past guards, it is not an
              invisibility cloak. If you have used the off-game rule you should
              immediately return to your Safe-zone and re-spawn. You can also use it to
              comment off game, if for instance you simply want the role playing toned
              down a bit.</p>
            <h3>Game on</h3>
            <p>If the game is for any reason called "game off", the call to resume is
              "game on".</p>
            <h2>Berget dollars</h2>
            <p>There is in-game money used during the game to buy wares, services and
              weapons for some units. This money has no value outside the game but is
              able to give players and their team an advantage in game. Some soldiers
              and weapons go to the highest bidder. All money is distributed by Berget
              before and during the game. During the game players might receive money if
              they are fighting for one of the conflict fractions or they might barter
              or sell intelligence to the highest bidder and in that fashion get their
              hands on the money. Anyone is free to plunder any fallen player of
              Berget-dollars or any other ingame items. Money will look different from
              previous Berget games. Any attempts to counterfeit Berget-dollars and put
              them into circulation will lead to immediate ban.</p>
            <h1>Game Area</h1>
            <h2>The map</h2>
            <p>During the game you will have access to the online game map where you can
              follow game events and find your nearest target. Capture points, drops and
              other happenings will be visible on the real time updated map. You will be
              able to see who owns a capture point and if it is contested.</p>
            <h2>Off game areas</h2>
            <p>Some areas on the game area can be marked as “off game”, it can be due to
              restrictions from the land owners so please show respect and avoid those
              areas.</p>
            <h2>Capture points</h2>
            <h3>Districts &amp; capture points</h3>
            <p>A Capture Point (CP) is a totem/building or object within a district.
              Taking control of all Capture Points in a district gives you control of
              it. The one controlling a District receives all in-game advantages it may
              give you and you are able to respawn in the vicinity. Districts can only
              be taken over if at least one neighboring district is under control. Home
              bases count as districts and can never be taken over by opposing factions.
              Berget Area Control System (B.A.C.S.) Berget Area Control System is a live
              digital GSM/Satellite system over watching the game area. It keeps track
              of which faction is controlling the CPs. B.A.C.S. will be available for
              all players with an internet connection and smartphone/laptop. There will
              also be a screen available in each base connected to the B.A.C.S.</p>
            <h4>How to take control of a Capture Point (CP)</h4>
            <p>These instructions will be posted next to the Capture Point during the
              game.</p>
            <h5>Claim</h5>
            <p>Inside the Capture Point you have to push a button with the color of your
              faction. This marks your presence in the area. (A signal will be sent to
              B.A.C.S to mark your presence publicly)</p>
            <h5>Verify</h5>
            <p>Then, you have to defend the Capture Point for 30 min and push the button
              again. It will light up to indicate 30 min has passed. When pushed for the
              second time, the Capture Point is yours! (A signal will be sent to B.A.C
              to update the area as Claimed.) NOTE! You have only 60 sec to verify,
              otherwise, the Capture Point goes back to Claim-mode.</p>
            <h5>Mark</h5>
            <p>When the button is pushed the second time the Capture Point is yours. You
              can now raise the flag if available on the position for your faction to
              visually mark this. At the same time, the Capture Point will be
              electronically marked with the verified team color.</p>
            <h3>Fortified positions</h3>
            <p>Some TP (tactical points) and SP (supply points) will be heavily
              fortified bunkers and can only be eliminated with the BAVS system or by
              shooting everyone inside. The Bunkers are constructed to be easily
              defended and the best way to eliminate everyone on the inside is to shoot
              the BAVS sensor on top of the bunker to simulate a penetrating hit killing
              everyone on the inside. We will use the same system as with the vehicles.
              Different objects and vehicles may be more or less hard to destroy.</p>
            <h4>Example</h4>
            <p>A penetrating hit is when the bunker has lost all its hit points. This
              message about the penetrating hit is enhanced by flashing lights and a
              long beeping sound. Upon getting this message all the players inside the
              bunker need to put on their death rags and exit the bunker towards their
              closest respawn. The bunker now needs to be recaptured to function.
              Players taking over the bunker need to hold down a button on the inside to
              successfully capture it. The bunker may not be used unless your team has
              captured it. The destruction and capturing of the bunker has been
              wirelessly monitored and gamemaster HQ will get the information by GSM
              transmitter. Players killed inside a bunker by a penetrating hit cannot be
              healed by a medic and must proceed to respawn. Explosives may not be used
              to take out a bunker and will have no effect.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'GameRules'
}
</script>

<style scoped>
 img { max-width: 100% }
</style>
