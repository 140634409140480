<template>
  <div class="segment">
    <div class="content narrow">
      <!-- <div class="header">GAME <span class="text-highlight">INFO</span></div> -->

      <div class="post column">
        <div class="post-banner">
          <img src="@/assets/pagebanner-info.jpg">
        </div>
        <div class="post-content-wrapper">
          <div class="post-content centered-image">
            <img src="@/assets/2024/20-years-badge-BERGET-EVENTS.png" alt="">
          </div>
          <div class="post-header text-highlight-red">BERGET 20 <span style="color:white"> - Updates for 2024</span></div>
            <pre class="post-content">
              Now is the time... After almost two decades of Berget Events we are planning an epic celebration of the 20th anniversary this summer. Our team have been hard at work making sure that this summer's event will be extraordinary.

              We have a beautiful, large game area with rivers, bridges, lakes and deep forests, perfect for airsoft.

              In the center of the game area we will build up an abandoned military base that will be held by one of the smaller teams (Yellow) and function as the main objective for the two opposing teams (Red & Blue). We will add a fourth team called Raiders (Black & white). They will roam the game area as the native cartel / MC gang and a guns for hire syndicate/clan.

              We will use shipping containers, excavators, and a lot of props to create a battlefield completely unlike anything you have experienced on Berget before. There will be an extensive network of trenches, bunkers and CQB buildings in the central part of the map, and several complex objects that will require teamwork and multiple stages to conquer.

              The two bigger teams will start on opposite sides of the playing area and only one rebel force will fight for its survival in the middle.

              There will be a civil trading station and an associated camp where all three sides can do business or where tired soldiers can escape the harsh reality for a while.

              We've spent time understanding how our planned game area will work to ensure better movement of people and vehicles, and as below we will tune how vehicles, road clearing etc works to bring the balance needed for the best possible experience.

              The vehicles will last much longer before being knocked out and a "non-penetrating hit" sound/flash will be introduced along with more hit points to counter the increased amount of anti-tank weapons. Tanks and the heaviest class will now be much harder to eliminate and will be used as break through vehicles.

              The game area will have several bridges that will have "new rules" regarding blowing up and repairing.

              New for this year is that all capture points will not be visible on the map from the start, but these must be found by your own team to be activated.

              We will provide Starlink and Wi-Fi to all bases as well as some capture points on the map for Wi-Fi access.

              The shop with airsoft equipment, bullets and guns will be the biggest we ever had, with an amazing selection you won't see at any other airsoft event.

              We have many more epic surprises planned both in and off game to ensure that Berget 20 will be an anniversary that all players remember for a long time!
            </pre>
        </div>
      </div>

      <div class="post column">
        <div class="post-content-wrapper">
          <div class="post-header">General information</div>
          <pre class="post-content">
            Berget is the annual airsoft events organized by the company Berget-Events. Berget is an airsoft-game for anyone who loves airsoft beyond Speedball, like authentic military feel and props. Berget Events provides camps for all factions with the necessary living logistics. Participants work together in big teams and each team has a military structure and overall objectives that the participants must follow. We put a lot of focus on the integration between LARP and airsoft-elements to create more dynamic gameplay.

            During the event, you have the opportunity to experience firefights with hundreds of players at the same time, well organized military bases, airstrikes, bunkers, attacks on vehicle convoys and maybe even Airsoft tanks fighting.

            If this is your first Berget-game you need to carefully read all the information on our website and also the Veterans corner in our forum. Berget is not like a Sunday game on a small field where you get action every 10 minutes. The game area is big with demanding terrain and you need to be ready to walk long distances.

            After choosing your team you will be placed in a military structure with officers and commanders. You will always be placed in the same squad/platoon as your friends as long as you register with the same team-tag.
          </pre>
        </div>

      </div>

      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">BERGET 20 - 2024</div>
          <pre class="post-content">
            <strong>Game Location</strong>

            Game area and Safezone in Backsjon (Backsjön) north of Sollefteå. There will be signs with B20 last part of the road. GPS: 63.32891125533918, 17.306271563157914

            <strong>Game area Map</strong>

            TBA
          </pre>
        </div>
      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">THE GAME AREA</div>
          <pre class="post-content">
            <b>Scheduled times, dates & location</b>

            Game location: To be announced early January
            GPS coordinates to Safezone: To be announced early January
            Dates: 25th of June - 30th of June 2024.

            <b>Please note!!!</b>

            Game starts: 21:00 CET 26th of June, do not show up att game-area before 25th of june!
            Safezone will be open during 09:00 - 24:00
            Check-in opens: 09:00 25th of June
            Team Gatherings: All players shall gather in their home base before 19:00 CET the 26th of June 2023.
            Game start: 21:00 CET 26th of June
            Game ends: 15:00 CET 29th of June
            Check-out opens: 12:00 29th of June (Returning of Radios, AT4’s, BAVS hit receivers, 40mm grenades, officer patches etc.)

            NOTE!

            Safezone, Game area and parking won't be open until 25th of june! Before that you have to stay at camping or hotel/hostel.

            Contacts:

            General questions: info@berget-events.com
            Payment and invoices: payment@berget-events.com
            Partners and companies: info@berget-events.com
            Phone: Closed until June 2024, please use email until then
            Forum: Temporarily closed, new communication channels TBA
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">TRAVELING &amp; WEAPON TRANSPORT</div>
          <pre class="post-content">
            Please note that Berget Transport is an extra service and not included in your game and camping ticket. The closest airport to the game area is Midlanda (Sundsvall &amp; Timrå Airport), and the closest bus/ station is Sollefteå station. We can arrange transport from/to both.

            When you travel by airplane to Sweden, you should travel to Arlanda (Stockholm) and then take a plane to Midlanda (Sundsvall). The price is about 30~40 EURO to travel from Arlanda to Midlanda if you book in advance. Use the Swedish airlines service for this: http://www.sas.se/ and click on the English flag in the left corner.

            NOTE! When booking your roundtrip to Berget please keep this in mind:

            When flying to Sweden you will arrive at Stockholm Airport (Arlanda). The transport option we provide is from Sundsvall airport (Midlanda). So you need to book a connection flight from Stockholm to Sundsvall where we pick you up (and leave you after the game) if you order our transport service.

            If you book in advance the price is about 30~40 EURO one way for this connection flight.

            You can also travel from Arlanda (Stockholm) to Sollefteå by bus or train, it takes 6 and 3 hours respectively and costs around 40-50 EURO.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Arrivals &amp; DEPARTURES</div>
          <pre class="post-content">
            Berget transport service will be at your disposal between 08:00 and 24:00, given that you have booked and paid for this transportation in advance. For your own convenience we need to know specific times and dates when you are planning to arrive and depart from Midlanda airport, Sundsvall train station or Sollefteå station. We also need to know when you will be leaving the event.

            Send this information to payment@berget-events.com with the topic "travel-service"

            Weapon transport Customs in Sweden will not confiscate airsoft weapons. The transport within Swedish borders will not be a problem either. We will contact and inform Customs about Berget in advance. For your safety please mark the tip of your gun barrels with red/orange tape and bring some papers about Berget. Still, if any problem arises, just ask customs to contact us and we will do our best to sort it out.

            It is worth noting that German customs often confiscate airsoft weapons. Therefore, if you are planning to travel through Germany, there is an option to send your guns to us and collect them at check-in.

          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Sending your weapons </div>
          <pre class="post-content">
            To avoid problems or delays with customs we have arranged an option to send your guns to Sweden by mail/speed post.

            You must pack your guns properly and place a paper inside the package with contact info to you, name, phone, complete address and booking numbers. Please also add a clearly visible note on the outside of the package with your Team name and the name on your contact person. Also mark your equipment with notes saying it's your personal sports equipment.

            After you package is sent, please send us an email to info@berget-events.com so we can keep a track of how many packages that are incoming.

            Send the package to:

            K-Bygg c/o Berget-events
            Övergårdsvägen 17
            881 41 Sollefteå
            Sweden
            Phone: +46 768031526 (For shipping company only)

            Mark pallet / package "Berget 20"

            Make sure that the package is delivered to the address above at the 16:th of June at the latest.&nbsp; Later that week there are holidays in Sweden and shipping companies may not deliver in the end of the week.

            Remember to check the delivery time before sending, if you don't use speed post it can take over 4 weeks before the package arrives to us. There is an administrative fee of "75 EURO" per package (max 55 KG) for this service and you will have to pay this cash at check in to receive your goods. The administrative fee for a pallet (max 800 KG) is "195 EURO"

            We strongly recommend that you book the return shipping as well for pickup on the same address on&nbsp;4th of july. This will in most cases be cheaper than contacting a Swedish company.

            If you for some reason cannot book the return shipping by yourself, we can do this for you. To be able to do this, we need the following information:

            - Destination address along with contact information
            - Measurements and weight of each package

            When we have obtained this information, we will contact a shipping company for a quotation. You will receive the price, and are to pay us in advance.

            If the packages are bigger or heavier than you have advised, and thus leading to a greater fee. The package won't be shipped until we have received additional funds, this including fees for storage at the shipping company.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Camp logistics </div>
          <pre class="post-content">
            Berget Events will provide military tents or tent space according to your booking. In the camps there will be places for sleeping, fresh drinking water, toilets, sanitation stations and 220 volt generators.

            Small booths will also be placed in the bigger bases along with a crew-help desk. All battalion, company and platoon commanders will be equipped with a cellphone and High output radio for better means of communication with the crew.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">PARKING</div>
          <pre class="post-content">
            There will be car parks for participants located near the main Safe-zone. We will not be responsible for vehicles left in these parking areas and will not provide security for them. We have informed police and local security companies, who have agreed to check these areas as they pass by.

            NOTE!

            You are not allowed to park your off-game car in your base. Civilian cars that need to be in the base for safety reasons have to be covered completely in camo nets or green tarps.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">STORAGE</div>
          <pre class="post-content">
            There will be storage space available in the main Safe-zone, for small equipment you don't want to carry around in the field. This will cost 10 EURO, for which you get supervised storage and a ticket to collect your kit. If you lose your ticket, you will need to show your identification to retrieve your gear.

            To avoid the risk of theft, we recommend that your team store objects of value in a large wooden box with a lock outside the tent. These can be rented from Berget-events for 35 EURO.

          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">CHECK-IN</div>
          <pre class="post-content">
            All participants must carry valid identification, such as a driving license, ID card or passport. We also recommend that you write down your booking reference to expedite the check-in process. Check-in will open at 09.00 on tuesday for early arrivals.

            Immediately following check-in, players will be shown on map to the relevant base to be able to prepare for the game. We expect the game to begin at 21.00 on Wednesday. Players arriving after this must report to Base commander.

          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Water </div>
          <pre class="post-content">
            All bases and main Safe-zone will have good quality drinking-water 24/7 available in large tanks. We will have staff taking care of filling these 1000 liter tanks when necessary.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Food and vending </div>
          <pre class="post-content">
            All those who have pre-ordered and paid for food will get it delivered to their base around 11:00 and 17:00 on Thursday, Friday and Saturday.

            Personally supplied food may be cooked in designated areas in the field or in the base.

          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Sleeping</div>
          <pre class="post-content">
            No camping is allowed at the game area outside of the Berget appointed base camps. Many sleeping facilities will be pre-built by Berget-crew, and you will get a spot in a military tent along with your teammates. There is also an option to bring your own tent. Players who wish to do so are allowed, under the condition that the tents meet the requirements listed below:

            - Big military tent for at least twelve or more people.

            - The tent must be OD or green camouflage.

            - You will raise the tent in an area designated to you.

            - Tents must be clearly marked with team and company name.

            Base staff will show you the right location for your tent.

            The tent must be approved by Berget-events beforehand. Therefore send a picture and information of the tent to info@berget-events.com.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Waste </div>
          <pre class="post-content">
            Any waste, of whatever kind, must be disposed of responsibly. Dustbins will be provided at designated points. If you stop in the woods for a snack you will be expected to remove all your rubbish to the nearest dustbin. If we catch anyone throwing trash in the forest we will ban this player from game area.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Power supply </div>
          <pre class="post-content">
            There will be limited access to electricity during the event, and it will be primarily used for lighting and limited battery charge in the bases.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">INTERNET</div>
          <pre class="post-content">
            Berget-events will have a wireless connection in the main safe-zone.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Hygiene </div>
          <pre class="post-content">
            We will supply toilets in the main Safe-zone and in all main bases. We will also provide showers in main Safe-zone. We strongly recommend that everyone washes daily since poor hygiene can lead to skin and stomach problems. Bring towel, soap and shampoo.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Security and Safety </div>
          <pre class="post-content">
            Swedish laws, naturally, apply during the event and apply above all other rules before, during and after Berget. If you are one of our overseas players and have questions about rules and laws check with the Swedish Police or contact us.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">The Right of Public Access </div>
          <pre class="post-content">
            In Sweden, everyone has the right to be out in the countryside - visitors from abroad as well as Swedish citizens. We call this "the Right of Public Access", Allemansrätt, a benefit not found in many other countries. However, it is not only a great freedom, it is also a great responsibility; our freedom in the countryside must not infringe upon the freedom of others.

            The Right of Public Access requires consideration, responsibility and good judgment. We must not damage the landscape or animal life, and we must show consideration for both landowners and for others who are out in the countryside.

            The essence of this Right is concisely expressed by the phrase, "Do not disturb, do not destroy".

            The Right of Public Access applies on the game areas, and this means amongst other things that no damage may be done to the area or its flora and fauna. In other words, you are for example not allowed to cut down trees!

          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Personal Safety </div>
          <pre class="post-content">
            With participant numbers over 1000 players, a high degree of safety consciousness is vital to avoid major injuries.

            In an emergency you should phone 112, the emergency number for use when an immediate response is required. Use this number to contact police, ambulance, fire brigade and the other emergency services in situations where there is an imminent risk, a casualty or a crime in progress.

            In non-emergency situations you should usually contact the local police in the area where the crime has occurred. To contact local police you should either phone them at 114 14, or go to the nearest police station.

            In conjunction with local authorities and rescue services, we have implemented a number of safety measures. We have conducted a safety meeting, arranged by the local council, which involved representatives from the emergency services, the council and local and regional authorities. We have also consulted with a number of other bodies to develop a set of rules and measures to protect the participants as far as possible. There will be trained nurses or paramedics at Safe-zone to help during accidents.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Insurance/Responsibility </div>
          <pre class="post-content">
            All participation in Berget-games is at your own risk! It is considered a hobby activity entered into of your own free will, and is therefore not covered by the event’s insurance. In the case of injury or accident, your own insurance should apply. Therefore we strongly recommend that you check your policy to ensure that you are covered.

            All participants must carry valid identification, such as a Photo-ID card or passport.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Alcohol and Drugs </div>
          <pre class="post-content">
            We have an absolute zero tolerance policy for any form of alcohol or drugs during in-game. Any players found using either during the event will be ejected from the area without recompense. We have a good relationship with local police, and will have trained security personnel on duty.

            If anyone abusing substances refuses to leave, police will be called. We are of the opinion that weapons - real or replica - and alcohol should never be combined, so leave any alcohol or drugs at home. If you will be carrying any medication, inform the organizers before the event or at check-in.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Fire Hazards</div>
          <pre class="post-content">
            There will be a blanket ban on fire at Berget games, meaning that no fire will be permitted except in specially designated areas. This ban covers anything with a flame, including camping stoves and candles. No pyrotechnical devices like smokes &amp; grenades may be brought into the game area; any pyros used will be provided by Berget. We encourage all participants to keep an eye on both themselves and others; particularly smokers must be careful to completely extinguish any cigarettes.

            Special safety regulations will be in force at camps, particularly those with tents. Fire extinguishers will be available at all camps, and everyone will be fully briefed on their use and location.

          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">First Aid </div>
          <pre class="post-content">
            In the event of minor injuries like strains, sprains and friction burns we will have a medical team in the main Safe zone and in all bases. This team is led by a GP, assisted by a number of trained medics. All marshals (wearing hi-vis yellow waistcoats) have some degree of first aid training and can help in the event of an accident.

            In the event of more serious incidents, you should help the injured person if you are confident that you can do so safely. At the earliest possible opportunity, call the emergency services by dialing 112 and give the address Berget event. Then inform a marshal, by radio or telephone if necessary, and help guide the emergency services to the site.

            We encourage everyone to carry a small first aid kit or field dressing in their right leg pocket, since quick help is a major factor in determining recovery. It may also be wise to bring blister tape and Aspirin for your own use, but remember never to give water or oral analgesics to anyone who may have suffered injuries to the head or stomach. If you require prescription medication, we recommend that you carry more than you expect to need, and distribute the doses about your equipment, to minimize loss.

          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Allergies and Diseases</div>
          <pre class="post-content">
            If you are diabetic, asthmatic, allergic or suffer from any medical condition that could affect your performance during the game, or that mean you require medical attention, be sure to notify your teammates and tell them how to help you if you should need it. Carry any medicine with you and inform your teammates where to find it.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Eye Protection </div>
          <pre class="post-content">
            Everyone on the game area must wear eye protection, including while asleep. It is essential that you wear eye protection suited for airsoft and that you only use proper and tested protection. We also recommend players to wear dental shields to avoid tooth injuries. Naturally this rule also applies to the LARP contingent.

          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">The Game Area </div>
          <pre class="post-content">
            There is always a risk that real civilians find their way into the game area. While we have taken all possible measures to inform the public, everyone will be expected to help reduce the risk by explaining to them that the area is being used for an airsoft game and that it is dangerous to be there without eye protection.

            If you see a civilian during a firefight, or when a firefight is imminent, immediately shout "Cease fire! Game off! Civilian!” This should be repeated by all other players to make sure that everyone knows. Make your weapons safe and ensure that the civilian is informed about the event and politely escort the off the area before calling Game On.
          </pre>

        </div>

      </div>
      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Make Safe </div>
          <pre class="post-content">
            Before entering a Safe zone you must make your weapons safe. Remove the magazine and fire a few rounds in a safe direction (marked) to clear the chamber. It is absolutely prohibited to point a weapon at anyone inside a Safe zone, and the marshals will take a very dim view of any careless behavior. If necessary the person concerned will be asked to leave.
          </pre>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'GameInfo'
}
</script>
