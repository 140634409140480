<template>
  <div class="segment">
    <div class="content">
      <div class="header">MEDIA FROM PREVIOUS <span class="text-highlight">BERGET</span></div>

      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-header">Berget 2023 teaser</div>
          <iframe src="https://player.vimeo.com/video/884900401" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" width="920" height="518"></iframe>
        </div>
      </div>
      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-header">Berget 2022 teaser</div>
          <iframe src="https://player.vimeo.com/video/638810053?h=75ff170068" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" width="920" height="518"></iframe>
        </div>
      </div>

      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-header">Berget 16</div>
          <iframe src="https://player.vimeo.com/video/295053739?loop=1&amp;title=0&amp;byline=0&amp;portrait=0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" width="920" height="518"></iframe>
        </div>
      </div>

      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-header">Berget 14 Teaser</div>
          <iframe src="https://player.vimeo.com/video/187609709?loop=1&amp;title=0&amp;byline=0&amp;portrait=0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" width="920" height="518"></iframe>
        </div>
      </div>

      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-header">Berget 13 TEASER</div>
          <iframe src="https://player.vimeo.com/video/142814848?loop=1&amp;title=0&amp;byline=0&amp;portrait=0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" width="920" height="518"></iframe>
        </div>
      </div>

      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-header">Berget 11</div>
          <iframe src="https://player.vimeo.com/video/78481364?loop=0&amp;title=0&amp;byline=0&amp;portrait=0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" width="920" height="518" frame="0"></iframe>
        </div>
      </div>

      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-header">Berget 10</div>
          <iframe src="https://player.vimeo.com/video/78487210?loop=0&amp;title=0&amp;byline=0&amp;portrait=0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" width="920" height="518"></iframe>
        </div>
      </div>

      <div class="post">
        <div class="post-content-wrapper">
          <div class="post-header">Berget 9</div>
          <iframe src="https://player.vimeo.com/video/777112379?h=f02c7e2a49" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" width="920" height="518"></iframe>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Media'
}
</script>

<style scoped>
iframe { width: 100%; max-height: 700px; height: 50vw; border: 0 }
.post-content-wrapper { max-width: none }
.post div:first-child {
  margin-right: 0;
}
</style>
